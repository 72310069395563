.contactPageContainer {
  padding: 3% 18%;
}

.contactFormContainer {
  display: flex;
  background-color: white;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  padding: 6px;
  border-radius: 14px;
}

.contactInfo {
  background-color: #ed3737;
  padding: 22px 32px;
  border-radius: 14px;
  color: #e5cdff;
  width: 100%;
}

.formDescription {
  color: white;
  max-width: 90%;
  line-height: 24px;
}

.contactInformationTitle {
  color: white;
  font-weight: 500;
}

.contactTitle {
  text-align: center;
  color: white;
}
.contactSubDescription {
  text-align: center;
  color: white;
}

.contactMethod {
  display: flex;
  align-items: center;
  color: white;
}

.icon {
  font-size: 20px;
  padding-right: 16px;
  color: white;
}

.formContainer {
  padding: 22px 32px;
  width: 100%;
  height: 100%;
}

.textInput {
  width: 90%;
  padding: 12px 22px;
  border-radius: 22px;
  border: 1px solid #e1dfec;
  outline: none;
}

.formSection {
  padding-bottom: 6px;
}

.label {
  font-size: 14px;
}

.textInput:hover {
  border-color: #ed3737;
  transition: 0.5s;
}

.textarea {
  resize: none;
  font-family: "Inter", sans-serif;
  height: 75px;
  border-radius: 16px;
}

.submitButton {
  width: 100%;
  padding: 16px 32px;
  border-radius: 22px;
  border: none;
  color: white;
  font-size: 16px;
  background-color: #ed3737;
  margin-top: 12px;
}

.submitButton:hover {
  background-color: #d42a2a;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .contactFormContainer {
    flex-direction: column;
    padding: 12px;
  }
  .formContainer {
    padding: 0px;
    box-sizing: border-box;
  }
  .contactInfo {
    box-sizing: border-box;
  }
  .textInput {
    width: 100%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1410px) {
  .contactPageContainer {
    padding: 5% 10%;
  }
}

@media screen and (max-width: 1100px) {
  .contactPageContainer {
    padding: 5% 5%;
  }
}

@media screen and (max-width: 800px) {
  .contactPageContainer {
    padding: 5% 5%;
  }
}

@media screen and (max-width: 600px) {
  .contactPageContainer {
    padding: 5% 4%;
  }
}

@media screen and (max-width: 500px) {
  .contactPageContainer {
    padding: 10% 4%;
    margin-top: 25px;
  }
}
