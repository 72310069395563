.navigationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 20px 40px;
  padding-bottom: 0;
}

.logo {
  display: flex;
  align-items: center;
}

.brandName {
  font-size: 2rem;
  font-weight: 400;
}

.logoImage {
  width: 75px;
  height: 75px;
  margin-right: 10px;
}

.link {
  color: white;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 400;
  margin-right: 20px;
}

.link:hover {
  opacity: 0.8;
}

@media screen and (max-width: 600px) {
  .navigationContainer {
    justify-content: center;
    padding: 0px;
    padding-top: 40px;
  }
  .logo {
    display: none;
  }
  .link {
    font-size: 1.5rem;
  }
}
