.homePageContainer {
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-color: #1a1a1a;
}
.contentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.left {
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 50%;
  margin-right: 80px;
}

.right {
  max-width: 50%;
  margin-left: 80px;
  padding-top: 4%;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.phone {
  height: 850px;
}

.header {
  color: white;
  font-weight: 600;
  font-size: 82px;
  margin: 0px;
}

.subHeader {
  color: white;
  font-weight: 600;
  font-size: 30px;
  max-width: 600px;
  text-align: left;
}

.download {
  width: 250px;
}

.downloadImages {
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 20px;
}

.downButton {
  width: 50px;
  height: 50px;
  border: 1px solid white;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);

  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.downArrow {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1150px) {
  .left {
    margin-right: 40px;
  }
  .right {
    margin-left: 40px;
  }
}
@media screen and (max-width: 1050px) {
  .phone {
    height: 600px;
  }
  .right {
    max-width: 40%;
    margin-left: 30px;
  }
  .left {
    margin-right: 30px;
    max-width: 60%;
  }
}
@media screen and (max-width: 975px) {
  .contentContainer {
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-top: 20%;
  }
  .right,
  .left {
    max-width: 100%;
    margin: 0%;
  }
  .header,
  .subHeader {
    text-align: center;
  }
  .content {
    align-items: center;
  }
  .phone {
    display: none;
  }
}
@media screen and (max-width: 570px) {
  .download {
    width: 200px;
  }
  .header {
    font-size: 65px;
  }
  .subHeader {
    font-size: 24px;
  }
}
@media screen and (max-width: 475px) {
  .download {
    width: 175px;
  }
  .header {
    font-size: 58px;
  }
  .subHeader {
    font-size: 22px;
  }
}
@media screen and (max-width: 380px) {
  .download {
    width: 150px;
  }
  .header {
    font-size: 52px;
  }
  .subHeader {
    font-size: 20px;
  }
}
